/**
 * @description This is the Contact Section component that is used
 * throught the application. It is used to quickly org a list
 * of contacts based on a particular contact type. It has the ability to
 * add a contact with its built in pick a user dialog
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module components/AddressSection
 */

// Packages
import React, { useState, useContext } from "react";

// MUI Components
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";

// Components
import PickAnAddressDialog from "components/PickAnAddressDialog";
import AddressCard from "components/AddressCard";

// Context
import MeContext from "../../contexts/MeContext";
import OrgContext from "../../contexts/OrgContext";

// Util
import * as userUtil from "util/userUtil";

// Styles
import { useStyles } from "./styles";

// Data
import ROLES from "enums/ROLES";

export default function AddressSection(props) {
  const { addresses } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressToUpdate, setAddressToUpdate] = useState();
  const [snack, setSnack] = React.useState({ open: false, note: null });

  const me = useContext(MeContext);
  const org = useContext(OrgContext);

  function handleShowDialog() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function getAddressCards() {
    if (!addresses || !Array.isArray(addresses)) return;

    //sort the addresses in created order
    addresses.sort((a, b) => {
      if (a.created < b.created) return -1;
      if (a.created > b.created) return 1;
      return 0;
    });

    return addresses.map((address, index) => {
      return (
        <AddressCard
          key={address.addressId}
          address={address}
          handleRemove={handleRemove}
          onClickUpdate={onClickUpdate}
          index={index}
        />
      );
    });
  }

  function onClickUpdate(address) {
    setAddressToUpdate(address);
    setOpen(true);
  }

  async function handleUpdate(
    placeId,
    formattedAddress,
    addressComponents,
    addressLine2,
    addressId,
    addressLine1,
    city,
    stateName,
    zip
  ) {
    await props.handleUpdate(
      placeId,
      formattedAddress,
      addressComponents,
      addressLine2,
      addressId,
      props.addressType,
      addressLine1,
      city,
      stateName,
      zip
    );

    setSnack({ open: true, note: `${props.addressType.title} updated` });
  }

  async function handleChange(
    placeId,
    formattedAddress,
    addressComponents,
    addressLine2,
    addressId,
    addressLine1,
    city,
    stateName,
    zip
  ) {
    setLoading(true);
    setOpen(false);
    if (addressId) {
      await handleUpdate(
        placeId,
        formattedAddress,
        addressComponents,
        addressLine2,
        addressId,
        addressLine1,
        city,
        stateName,
        zip
      );
    } else {
      await handleAdd(
        placeId,
        formattedAddress,
        addressComponents,
        addressLine2,
        addressLine1,
        city,
        stateName,
        zip
      );
    }
    setAddressToUpdate(null);
    setLoading(false);
  }

  async function handleAdd(
    placeId,
    formattedAddress,
    addressComponents,
    addressLine2,
    addressLine1,
    city,
    stateName,
    zip
  ) {
    await props.handleAdd(
      placeId,
      formattedAddress,
      addressComponents,
      addressLine2,
      props.addressType,
      addressLine1,
      city,
      stateName,
      zip
    );

    setSnack({ open: true, note: `${props.addressType.title} added` });
  }

  async function handleRemove(addressId) {
    setLoading(true);
    await props.handleRemove(addressId, props.addressType);
    setLoading(false);

    setSnack({ open: true, note: `${props.addressType.title} removed` });
  }

  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      {
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={() => setSnack({ open: false, note: null })}
          autoHideDuration={5000}
          autoFocus={false}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span>{snack.note}</span>}
        />
      }
      <Paper className={classes.root} elevation={1}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          className={classes.topSection}
          data-test={"Add-" + props.addressType.title}
        >
          <Grid item>
            <Typography variant="h5" component="h4">
              {props.addressType.title}
            </Typography>
          </Grid>
          <Grid item>
            {userUtil.canDoOrg(me, ROLES.READ_WRITE, org) && (
              <Fab
                size="small"
                color="primary"
                aria-label="Add"
                data-test="AddFab"
                name="AddFab"
                id="AddFab"
                className={classes.fab}
                onClick={handleShowDialog}
                
              >
                <AddIcon data-test="Add" />
              </Fab>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          data-test={props.addressType.title}
        >
          {getAddressCards()}
        </Grid>
        {open && (
          <PickAnAddressDialog
            address={addressToUpdate}
            handleClose={handleClose}
            onChange={handleChange}
          />
        )}
      </Paper>
    </React.Fragment>
  );
}
