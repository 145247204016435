// Packages
import React, { useState, useEffect } from "react";
//import Geosuggest from "react-geosuggest";

// MUI Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

// Styles
import { useStyles } from "./styles";
import STATES from "enums/STATES";

export default function PickAnAddressDialog(props) {
  //const { address } = props;
  const classes = useStyles();
  const [address, setAddress] = useState(props);

  const [addressComponents, setAddressComponents] = useState(
    (props && props.address && props.address.addressComponents) ? props.address.addressComponents : null
  );
  const [formattedAddress, setFormattedAddress] = useState(
    props.address && props.address.formattedAddress ? props.address.formattedAddress : null
  );
  const [addressLine2, setAddressLine2] = useState(
    props && props.addressLine2 ? props.addressLine2 : null
  );
  const [placeId, setPlaceId] = useState(
    props.address && props.placeId ? props.placeId : null
  );

  const [debounceAddressLine1, setDebounceAddressLine1] = useState();
  const [debounceAddressLine2, setDebounceAddressLine2] = useState();
  const [debounceStateName, setDebounceStateName] = useState("");
  const [debounceCity, setDebounceCity] = useState();
  const [debounceZip, setDebounceZip] = useState();

  const [addressLine1, setAddressLine1] = useState();
  const [stateName, setStateName] = useState();
  const [stateCode, setStateCode] = useState();
  const [invalidState, setInvalidState] = useState(false);

  const [city, setCity] = useState();
  const [zip, setZip] = useState();

  const [updateAddressLoaded, setUpdateAddressLoaded ] = useState(false);


useEffect( () => {
    if(!addressComponents || addressComponents.length === 0 || updateAddressLoaded){
      return;
    }
    try {
      let tempAddressLine1;
      let tempAddressLine2;
      let tempCity;
      let tempState;
      let tempZip;
      const addressComponentsObj = JSON.parse(addressComponents);
      for (let i = 0; i < addressComponentsObj.length; i++) {
        const component = addressComponentsObj[i];
        if(!component.types){
          continue;
        }
        if (component.types.includes("simple_street_address")) {
          tempAddressLine1 = component.long_name;
        } else if (component.types.includes("simple_street_address2")) {
          tempAddressLine2 = component.short_name;
        } else if (component.types.includes("locality")) {
          tempCity = component.short_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          tempState = component.long_name;
        } else if (component.types.includes("postal_code")) {
          tempZip = component.short_name;
        }
      }
      setAddressLine1(tempAddressLine1);
      setDebounceAddressLine1(tempAddressLine1);

      setAddressLine2(tempAddressLine2);
      setDebounceAddressLine2(tempAddressLine2);

      setCity(tempCity);
      setDebounceCity(tempCity);

      setStateName(tempState);
      setDebounceStateName(tempState);

      setZip(tempZip);
      setDebounceZip(tempZip);
      setUpdateAddressLoaded(true);

    } catch (error) {
      console.error("could not parse address", error);
    }
}, [addressComponents]);
  
useEffect(() => {
  const timeoutId = setTimeout(() => {
    setAddressLine1(debounceAddressLine1);
  }, 500);
  return () => clearTimeout(timeoutId);
}, [debounceAddressLine1, 500]);

useEffect(() => {
  const timeoutId = setTimeout(() => {
    setAddressLine2(debounceAddressLine2);
  }, 500);
  return () => clearTimeout(timeoutId);
}, [debounceAddressLine2, 500]);

useEffect(() => {
  const timeoutId = setTimeout(() => {
    setCity(debounceCity);
  }, 500);
  return () => clearTimeout(timeoutId);
}, [debounceCity, 500]);

function checkState(event){
    let found = false;
    
    Object.values(STATES).map((stateItem) => {
      if(stateItem.title.toLowerCase() === debounceStateName.toLowerCase() ){
        setStateCode(stateItem.key);
        setStateName(stateItem.title);
        found = true;
        setInvalidState(false);
      }
      if(stateItem.key.toLowerCase() === debounceStateName.toLowerCase() ){
        setStateCode(stateItem.key);
        setStateName(stateItem.title);
        setInvalidState(false);
        found = true;
      }
    });
    if(!found){
      setInvalidState(true);
      setStateCode(debounceStateName);
      setStateName(debounceStateName);
    }
}

useEffect(() => {
  const timeoutId = setTimeout(() => {
    setStateName(debounceStateName);
  }, 500);
  return () => clearTimeout(timeoutId);
}, [debounceStateName, 500]);


useEffect(() => {
  const timeoutId = setTimeout(() => {
    setZip(debounceZip);
  }, 500);
  return () => clearTimeout(timeoutId);
}, [debounceZip, 500]);


  useEffect(() => {
    let newAddress = {};
    newAddress = JSON.parse(JSON.stringify(address));
    const newFormattedAddress = `${addressLine1}, ${addressLine2 ? addressLine2+', ' : ""}${stateName}, ${city}, ${zip}`;
    newAddress.formattedAddress = newFormattedAddress;
    setAddress(newAddress);
    setFormattedAddress(newFormattedAddress);
    const addressNumbers = addressLine1 ? addressLine1.split(" ")[0] : "";
    const addressName1 = addressLine1 ? addressLine1.replace(addressNumbers, '') : "";

    const newAddressComponents = [{"long_name":addressNumbers,"short_name":addressNumbers,"types":["street_number"]},{"long_name":addressName1,"short_name":addressName1,"types":["route"]},{"long_name":city,"short_name": city,"types":["locality","political"]},{"long_name":stateName,"short_name":stateCode,"types":["administrative_area_level_1","political"]},{"long_name":"United States","short_name":"US","types":["country","political"]},{"long_name":zip,"short_name":zip,"types":["postal_code"]},{"long_name":addressLine1,"short_name":addressLine1,"types":["simple_street_address"]},{"long_name":addressLine2,"short_name":addressLine2,"types":["simple_street_address2"]}];
    setAddressComponents(JSON.stringify(newAddressComponents));
    setPlaceId('placeIdPlaceHolder');

  }, [addressLine1, addressLine2, stateName, city, zip]);

  /*function onSuggestSelect(suggestion) {
    if (!suggestion) return;

    if (suggestion.placeId) {
      setPlaceId(suggestion.placeId);
    }

    const gmaps = suggestion.gmaps;

    if (gmaps) {
      if (gmaps.formatted_address) {
        setformattedAddress(gmaps.formatted_address);
      }
      if (gmaps.address_components) {
        setAddressComponents(JSON.stringify(gmaps.address_components));
      }
    }
  }*/

  function handleOk() {
    let addressId;
    if (props.address && props.address.addressId) {
      addressId = props.address.addressId;
    }

    props.onChange(
      placeId,
      formattedAddress,
      addressComponents,
      addressLine2,
      addressId,
    );
  }

  //const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title">Enter Address</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className={classes.suggestContainer}>
          {/*<Grid item xs={12} className={classes.suggestItem}>
            <Geosuggest
              onSuggestSelect={onSuggestSelect}
              initialValue={formattedAddress}
            />
          </Grid>*/
          <Grid item xs={12} className={classes.suggestItem}>
            <TextField
              className={classes.addressField}
              variant="outlined"
              label="Address"
              data-test="addressLine1"
              name="addressLine1"
              id="addressLine1"
              fullWidth
              value={debounceAddressLine1 ? debounceAddressLine1 : ""}
              onChange={(e) => setDebounceAddressLine1(e.target.value)}
            />
            <TextField
              className={classes.addressField}
              variant="outlined"
              label="Address Line 2"
              data-test="addressLine2"
              name="addressLine2"
              id="addressLine2"
              placeholder="Suite 100 or Attn: John Doe"
              fullWidth
              value={debounceAddressLine2 ? debounceAddressLine2 : ""}
              onChange={(e) => setDebounceAddressLine2(e.target.value)}
            />
            <TextField
              className={classes.addressField}
              variant="outlined"
              label="City"
              data-test="city"
              name="city"
              id="city"
              fullWidth
              value={debounceCity ? debounceCity : ""}
              onChange={(e) => setDebounceCity(e.target.value)}
            />
            <TextField
              className={classes.addressField}
              color={ (!invalidState || debounceStateName.length === 0)  ? "primary": "secondary"}
              variant={ ( !invalidState || debounceStateName.length === 0)  ? "outlined": "standard"}
              error={invalidState && debounceStateName.length >= 0}
              label="State"
              data-test="stateName"
              name="stateName"
              id="stateName"
              fullWidth
              value={debounceStateName ? debounceStateName : ""}
              onChange={(e) => setDebounceStateName(e.target.value)}
              onBlur={(e) => checkState(e.target.value)}
            />
              <TextField
              className={classes.addressField}
              variant="outlined"
              label="Zip"
              data-test="zip"
              name="zip"
              id="zip"
              fullWidth
              value={debounceZip ? debounceZip : ""}
              onChange={(e) => setDebounceZip(e.target.value)}
            />
          </Grid>
          }
          {/*placeId && (
            <React.Fragment>
              <Grid item xs={12} className={classes.addressLine2}>
                <TextField
                  variant="outlined"
                  label="Additional Info"
                  placeholder="Suite 100 or Attn: John Doe"
                  fullWidth
                  value={addressLine2 ? addressLine2 : ""}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} className={classes.maps}>
                {<iframe
                  title={formattedAddress}
                  width="100%"
                  height="150"
                  frameBorder="0"
                  src={`https://www.google.com/maps/embed/v1/place?q=place_id:${placeId}&key=${apiKey}`}
                  allowFullScreen
                />}
              </Grid>
            </React.Fragment>
          )*/}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary" data-test="cancel" name="cancel" id="cancel">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" variant="contained" data-test="ok" name="ok" id="ok">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
